import { isEmpty } from "@antv/util";
import { Spin } from "antd";
import { navigate } from "gatsby";
import { filter, map } from "lodash";
import get from "lodash.get";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  binanceWordImg,
  bitgetWordImg,
  ftxWhiteImg,
  tokocryptologoImg,
} from "../../../images";
import { sourceKey } from "../../../locales/config";
import { useTranslation } from "../../../locales/useTranslation";
import getProfiles from "../../../newApi/profile/getProfiles";
import getRobotProfiles from "../../../newApi/robots/getRobotProfiles";
import { triggerModal } from "../../../redux/actions/app-actions";
import { updateApiKeyActiveId } from "../../../redux/actions/product-actions";
import { loginSuccessful } from "../../../redux/actions/user-actions";
import { routes } from "../../../route";
import { AllExchangePlatforms } from "../../../utilities/startUp";
import Layout from "../../general/components/Layout";
import NavHeader from "../../general/components/NavHeader";
// markup
const NewsAndVideo = (props) => {
  // const location = useLocation();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  // const [languageType, setLanguageType] = useState("all");
  const [dataSource, setDataSource] = useState([]);
  // const [page, setPage] = useState(1);
  // const [selectedData, setSelectedData] = useState({});
  // const [viewNews, setViewNews] = useState(false);
  // const [binanceApiKey, setBinanceApiKey] = useState({});
  // const [ftxApiKey, setFtxApiKey] = useState({});
  const [profile, setProfile] = useState({});

  let user = get(props, "user.user");
  //   console.log(user);

  useEffect(() => {
    let profile = get(user, "profile");
    if (!isEmpty(profile)) {
      getProfiles(1, 0, {
        _id: get(profile, "_id"),
      })
        .then((res) => {
          // console.log(res);
          let data = get(res, "data");
          data = data[0];
          let robotProfiles = data?.robotProfiles || [];
          setDataSource(robotProfiles);
          setProfile(data);
        })
        .catch((err) => {
          console.log(err);
        });
      // getOrCreateRobotProfile({
      //   profileId: get(profile, "_id"),
      //   usedFor: "binance",
      //   exchange: "binance",
      // })
      //   .then((res) => {
      //     console.log(res);
      //   })
      //   .catch((err) => {
      //     console.log(err);
      //   });
    }
  }, []);

  // useEffect(() => {
  //   if (!isEmpty(profile)) {
  //     let promises = [];
  //     let robotProfiles = get(profile, "robotProfiles");
  //     if (!isEmpty(robotProfiles)) {
  //       let _idRobot = map(robotProfiles, (item, index) => {
  //         return get(item, "robotProfileId");
  //       });
  //       // console.log("_idRobot", _idRobot);
  //       if (!isEmpty(_idRobot)) {
  //         getRobotProfiles({
  //           _idIn: _idRobot,
  //         })
  //           .then((res) => {
  //             // console.log("res getRobotProfiles", res);
  //             let data = get(res, "data");
  //             setDataSource(data);
  //           })
  //           .catch((err) => {
  //             console.log(err);
  //           });
  //       }
  //     }
  //   }
  // }, [profile]);

  const platforms = [
    {
      title: "Binance",
      value: "binance",
      // img: binanceSmallIconImg,
      img: binanceWordImg,
      //   path: routes.apiKey.to(),
      width: 140,
      height: 28,
    },
    {
      title: "FTX",
      value: "ftx",
      // img: ftxIconImg,
      img: ftxWhiteImg,
      //   path: "",
    },
    {
      title: "Tokocrypto",
      value: "tokocrypto",
      img: tokocryptologoImg,
      width: 140,
      height: 35,
    },
    {
      title: "Bitget",
      value: "bitget",
      img: bitgetWordImg,
      width: 120,
      height: 30,
    },
  ];

  function changeApiKey(key) {
    // console.log(key);
    props.updateApiKeyActiveId(key);
    navigate(routes.apiKey.to({ platform: key }));
  }

  return (
    <React.Fragment>
      <Layout>
        <Spin spinning={loading}>
          <NavHeader
            showBack={true}
            onBack={() => {
              navigate("/");
            }}
            suffix={
              <React.Fragment>
                <div className="justify-end flex items-center">
                  {/* API 列表 API List */}
                  {t("apiList", sourceKey.api)}
                </div>
              </React.Fragment>
            }
          >
            <div className="pt-8">
              <div className="">
                {map(AllExchangePlatforms, (item, index) => {
                  let platform = filter(platforms, (p, index) => {
                    return get(p, "value") === item;
                  });
                  platform = platform[0];
                  let data = filter(dataSource, (p, index) => {
                    return get(p, "exchange") === item;
                  });
                  data = data[0];
                  // console.log("data", data);
                  return (
                    <div className="mb-4 px-8 text-lg">
                      <div className="mb-2 flex justify-between">
                        <div className="mr-2 pt-1">
                          <img
                            src={platform.img}
                            width={platform.width}
                            style={{ height: platform.height }}
                          />
                        </div>
                        <div>
                          {data?.apiBindedDay >= 1 ? (
                            <div>
                              <div
                                className="touchbox-bg-color rounded-2xl px-4 py-1"
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                  changeApiKey(item);
                                }}
                              >
                                {t("edit", sourceKey.api)}
                              </div>
                            </div>
                          ) : (
                            <div className="flex justify-start mt-2 ">
                              <div
                                className="bg-main-color-gradient rounded-2xl px-4 py-1 text-base"
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                  changeApiKey(item);
                                }}
                              >
                                {t("createNew", sourceKey.api)}
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                      {/* {data?.apiBindedDay >= 1 ? (
                        <div className="flex justify-between mt-2">
                          <div>
                            <span>
                              API #{index + 1}:{" "}
                              {get(data, "apiKey")?.substring(0, 15) || ""}
                            </span>
                          </div>
                          <div>
                            <div
                              className="touchbox-bg-color rounded-2xl px-4 py-1"
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                changeApiKey(item);
                              }}
                            >
                              {t("edit", sourceKey.api)}
                            </div>
                          </div>
                        </div>
                      ) : (
                        ""
                      )} */}
                    </div>
                  );
                })}
                {/* <div
                  className="bg-main-color-gradient p-2 m-2 rounded-3xl cursor-pointer"
                  onClick={() => {
                    //close();
                    navigate(routes.apiKeyPlatform.to());
                  }}
                >
                  <div className="flex justify-center">
                    {" "}
                    +{t("createNew", sourceKey.api)}
                  </div>
                </div> */}
              </div>
            </div>
          </NavHeader>
        </Spin>
      </Layout>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
});

const mapDispatchToProps = {
  loginSuccessful,
  triggerModal,
  updateApiKeyActiveId,
};
export default connect(mapStateToProps, mapDispatchToProps)(NewsAndVideo);
