import * as React from "react";
import APIKeyListing from "../components/apiKey/pages/APIKeyListing";
import SharedHeader from "../components/general/components/SharedHeader";

// markup
const APIKey = () => {
  return (
    <React.Fragment>
      <SharedHeader />
      <APIKeyListing />
    </React.Fragment>
  );
};

export default APIKey;
